import { Injectable } from '@angular/core';
import { baseURL, timeoutTime } from '../../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/timeoutWith';
import 'rxjs/add/operator/timeout';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  baseUrl = baseURL;
  constructor(private httpClient: HttpClient) {
  }

  getadminpagination(dataTablesParameters): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `realestatemanager/subuser_pagination`, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getadminpagination')));
  }

  getadminarchivedpagination(dataTablesParameters): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `realestatemanager/subuser_archieved_pagination`, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getadminpagination')));
  }

  getadmindeletepagination(dataTablesParameters): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `realestatemanager/subuser_deleted_pagination`, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getadminpagination')));
  }
  changeadminStatus(id, statusdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `realestatemanager/change_subuser_status/${id}`, statusdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error changeadminStatus')));
  }

  deleteadmin(id): Observable<any> {
    return this.httpClient.delete(this.baseUrl + `realestatemanager/delete_subuser/${id}`)
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error deleteadmin')));
  }

  createadmin(citydata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `realestatemanager/add_subuser`, citydata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error createadmin')));
  }

  updateadmin(id, updatedata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `realestatemanager/update_subuser/${id}`, updatedata, { headers } )
    .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error updateadmin')));
  }

  getbyidadmin(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.get(this.baseUrl + `realestatemanager/view_subuser/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getbyidadmin')));
  }

  archieveadmin(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `realestatemanager/archieve/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error archieveadmin')));
  }

  unarchieveadmin(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `realestatemanager/unarchieve/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error unarchieveadmin')));
  }

  updateadminlanguage(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `realestatemanager/change_lang/${id}`, {}, { } )
    .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error updateadmin')));
  }
}
