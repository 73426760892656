import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { AuthGuard } from './AlkiraManager/guards/auth-guard.service';

const routes: Routes = [

  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: ['no'], moduleId: [0] },
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'admin_roles',
        data: { full_module_route: 'admin_roles' },
        loadChildren: () => import('./AlkiraManager/roleandpermission/roleandpermission.module').then(module => module.RoleandpermissionModule)
      },
      {
        path: 'home',
        data: { full_module_route: 'default' },
        loadChildren: () => import('./AlkiraManager/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'tenant',
        data: { full_module_route: 'tenant' },
        loadChildren: () => import('./AlkiraManager/tenant/tenant.module').then(module => module.TenantModule)
      },
      {
        path: 'contract',
        data: { full_module_route: 'contract' },
        loadChildren: () => import('./AlkiraManager/contract/contract.module').then(module => module.ContractModule)
      },
      {
        path: 'lessor',
        data: { full_module_route: 'lessor' },
        loadChildren: () => import('./AlkiraManager/Lessor/lessor.module').then(m => m.LessorModule)
      },
      {
        path: 'inventory',
        data: { full_module_route: 'inventory' },
        loadChildren: () => import('./AlkiraManager/inventory/inventory.module').then(module => module.InventoryModule)
      },
      {
        path: 'repair',
        data: { full_module_route: 'repair' },
        loadChildren: () => import('./AlkiraManager/repair/repair.module').then(module => module.RepairModule)
      },
      {
        path: 'my-profile',
        data: { full_module_route: 'my-profile' },
        loadChildren: () => import('./AlkiraManager/my-profile/my-profile.module').then(module => module.MyProfileModule)
      },
     
      {
        path: 'user',
        data: { full_module_route: 'user' },
        loadChildren: () => import('./AlkiraManager/admin/admin.module').then(module => module.AdminModule)
      },
      {
        path: 'real-estate-manager',
        data: { full_module_route: 'real-estate-manager' },
        loadChildren: () => import('./AlkiraManager/real-estate-manager/real-estate-manager.module').then(module => module.RealEstateManagerModule)
      },
      {
        path: 'asset',
        data: { full_module_route: 'asset' },
        loadChildren: () => import('./AlkiraManager/asset/asset.module').then(module => module.AssetModule)
      },
     
      {
        path: 'master',
        data: { full_module_route: 'master' },
        loadChildren: () => import('./AlkiraManager/master/master.module').then(module => module.MasterModule)
      },
      {
        path: 'pages',
        data: { full_module_route: 'pages' },
        loadChildren: () => import('./AlkiraManager/cms/cms.module').then(module => module.CmsModule)
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      // {
      //   path: '',
      //   redirectTo: 'auth',
      //   pathMatch: 'full'
      // },
      {
        path: 'auth',
        loadChildren: () => import('./AlkiraManager/authentication/authentication.module').then(module => module.AuthenticationModule)
      },
      
    ]
  },




];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
