import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router, private translate:TranslateService) {
    this.translate.addLangs(['en', 'fr']);
    this.translate.setDefaultLang('fr');

    let lang = window.localStorage.getItem('app_language');

    if (lang == null) {
      this.translate.use('fr');
      window.localStorage.setItem('app_language', 'fr');
    } else {
      window.localStorage.setItem('app_language', lang);
      this.translate.use(lang);
    }
   }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
