import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-show-photos',
  templateUrl: './show-photos.component.html',
  styleUrls: ['./show-photos.component.scss']
})
export class ShowPhotosComponent implements OnInit {
  @Input() image: any;
  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
